import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import ImageLink from "../components/ImageLink";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import { graphql, Link } from "gatsby";
import showdown from "showdown";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const Title = styled(Link)`
  color: #000;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 1.1;
  padding-bottom: 10px;
  border-bottom: 1px solid #016597;
  margin-bottom: 20px;
`;

const CoroContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
`;

const Bio = styled.div``;

export default class CorosPage extends Component {
  render() {
    const { data } = this.props;

    const coros = [
      {
        name: "Coro Polifónico",
        fluid: data.coroPolifonicoImage.childImageSharp.fluid,
        url: "/coro-polifonico",
        bio: `“La palabra de Cristo more en abundancia en vosotros, enseñándoos y exhortándoos unos a otros en toda sabiduría, cantando con gracia en vuestros corazones al Señor con salmos e himnos y cánticos espirituales.” Colosenses 3:16 Reina-Valera 1960 (RVR1960)`
      },
      {
        name: "Coros Unidos",
        fluid: data.coroUnidosImage.childImageSharp.fluid,
        url: "/coros-unidos",
        bio: `“Jehová es mi fortaleza y mi cántico, Y ha sido mi salvación. Este es mi Dios, y lo alabaré; Dios de mi padre, y lo enalteceré.” Éxodo 15:2 Reina-Valera 1960 (RVR1960)`
      },
      {
        name: "Coro de Niños",
        fluid: data.coroNinosImage.childImageSharp.fluid,
        url: "/coro-de-ninos",
        bio: `“Pero Jesús dijo: Dejad a los niños venir a mí, y no se lo impidáis; porque de los tales es el reino de los cielos.” Mateo 19:14 Reina-Valera 1960 (RVR1960)`
      }
    ];

    return (
      <Layout>
        <Seo title="Los Coro" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  {coros.map(this.renderCoro)}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }

  renderCoro({ name, fluid, url, bio }) {
    return (
      <CoroContainer>
        <Title to={url}>{name}</Title>
        <ImageLink url={url} fluid={fluid} />
        <Bio
          dangerouslySetInnerHTML={{
            __html: converter.makeHtml(bio)
          }}
        />
      </CoroContainer>
    );
  }
}

export const query = graphql`
  query CorosPageQuery {
    coroPolifonicoImage: file(relativePath: { eq: "DSC07249.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    coroUnidosImage: file(relativePath: { eq: "DSC_4169.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    coroNinosImage: file(relativePath: { eq: "DSC_5471.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
